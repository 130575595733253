import React, { useState } from 'react';
import { jwtDecode } from 'jwt-decode';

// Components
import ResetPasswordSuccess from './ResetPasswordSuccess';
import PasswordInput from '../common/PasswordInput';
import InputValidation from '../validation/InputValidation';
import Alert from '../layout/Alert';

// Utils
import { isValidPassword } from '../../validation-utils/validationCheck';
import { setValidPasswordInput, setInvalidPasswordInput } from '../../validation-utils/toggleValidationFeedback';
import { resetPassword } from '../../utils/services';

const ResetPassword = (props) => {
  const [ formData, setFormData ] = useState({newPassword: ``, confirmPassword: ``});
  const [ isUpdated, setIsUpdated ] = useState(false);
  const [ passwordsMatch, setPasswordsMatch ] = useState(true);
  const { newPassword, confirmPassword } = formData;
  const { userId, token } = props.match.params;
  const decodedToken = jwtDecode(token);
  const isNonWhitelistAccessAllowed = decodedToken.roles.includes('non-whitelist-ok');
  const [alerts, setAlerts] = useState([]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const validNewPassword     = isValidPassword(newPassword);
    const validConfirmPassword = isValidPassword(confirmPassword);

    if (validNewPassword) setValidPasswordInput(`new-password-input-group`);
    else setInvalidPasswordInput(`new-password-input-group`);

    if (validConfirmPassword) setValidPasswordInput(`confirm-password-input-group`);
    else setInvalidPasswordInput(`confirm-password-input-group`);

    if (!validNewPassword) return;
    if (!validConfirmPassword) return;

    if (newPassword !== confirmPassword) {
      setPasswordsMatch(false);
      setValidPasswordInput(`new-password-input-group`);
      setInvalidPasswordInput(`confirm-password-input-group`);
      return;
    }

    const result = await resetPassword(userId, token, newPassword);

    if (result?.status!==200) {
      setAlerts([...alerts, {heading: 'Hang On!', msg:result.message, type:'danger'}]);
      setTimeout(() => setAlerts([]), 8000);
      return;
    }

    setIsUpdated(true);
  };

  const ResetPasswordForm = (
    <>
      <InputValidation formName='passwordResetForm' />
      <div
        className='row justify-content-center align-items-center'
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -75%)',
        }}
      >
        <div
          className='card mx-auto'
          style={{
            width: '50%',
            height: '50%',
            minWidth: '400px',
            minHeight: '300px',
            borderRadius: '6px',
          }}
        >
          <div className='text-center mx-auto' style={{ marginTop: '10px' }}>
            <div className='card-body'>
              <div style={{ marginBottom: '20px' }}>
                <div className='text-left' style={{ position: 'relative' }}>
                  <Alert alerts={alerts}/>
                </div>
                <p
                  className='text-nowrap'
                  style={{ fontSize: '16pt', marginBottom: '20px' }}
                >
                  Create new password
                </p>
                {/* the 1st 4 form attributes were added per 2024 WEBSEC pentest recommendations */}
                <form
                  spellcheck='false'
                  autocomplete='off'
                  autocorrect='off'
                  autocapitalize='off'
                  name='passwordResetForm'
                  method='post'
                  onSubmit={(e) => onSubmit(e)}
                  noValidate
                >
                  <div
                    id='new-password-input-group'
                    className='form-group'
                    style={{ marginBottom: '20px' }}
                  >
                    <PasswordInput
                      id='new-password-input'
                      elementId='newPasswordInput'
                      value={newPassword}
                      name='newPassword'
                      placeholder='Enter new password'
                      onChange={onChange}
                      required
                      invalidMessage={`New password field is required`}
                    />
                  </div>
                  <div
                    id='confirm-password-input-group'
                    className='form-group'
                    style={{ marginBottom: '20px' }}
                  >
                    <PasswordInput
                      id='confirm-password-input'
                      elementId='confirmPasswordInput'
                      value={confirmPassword}
                      name='confirmPassword'
                      placeholder='Confirm new password'
                      onChange={onChange}
                      required
                      invalidMessage={
                        passwordsMatch
                          ? `Confirm password field is required`
                          : `Confirm password must match new password`
                      }
                    />
                  </div>
                  <input
                    type='submit'
                    value='Save'
                    className='btn btn-primary btn-lg shadow-none'
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return isUpdated ? <ResetPasswordSuccess isNonWhitelistAccessAllowed={isNonWhitelistAccessAllowed}/> : ResetPasswordForm;
};

export default ResetPassword;