import React, { useState } from 'react';
import { Link } from 'react-router-dom';

//Context
import { useRelyingParty } from '../../context/RelyingPartyContext';

// Components
import InputValidation from '../validation/InputValidation';
import Alert from '../layout/Alert';

// Utils
import { isValidEmail } from '../../validation-utils/validationCheck';
import { setValidInput, setInvalidInput } from '../../validation-utils/toggleValidationFeedback';
import { gSubdomain } from '../../utils/config';
import { forgotPassword } from '../../utils/services';

const ForgotPassword = () => {
  const { relyingParty } = useRelyingParty();
  const [email, setEmail] = useState('');
  const [isSent, setIsSent] = useState(false);
  const [alerts, setAlerts] = useState([]);

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const validEmail = isValidEmail(email);

    if (validEmail) setValidInput(`email-input-group`);
    else setInvalidInput(`email-input-group`);

    if (!validEmail) return;

    const result = await forgotPassword(email);

    if (result?.status!==200) {
      setAlerts([...alerts, {heading: 'Hang On!', msg:result.message, type:'danger'}]);
      setTimeout(() => setAlerts([]), 8000);
      return;
    }

    setIsSent(true);
  };

  const emailForm = (
    <>
      <InputValidation formName='sendEmailForm' />
      <div className='text-center mx-auto' style={{ marginTop: '10px' }}>
        <Alert alerts={alerts}/>
        <div style={{ marginBottom: '20px' }}>
          <h6>IDgo Admin - {relyingParty?.name}</h6>
          <p style={{ fontSize: '16pt' }}>Password assistance</p>
        </div>
        <p
          className='text-left'
          htmlFor='email'
          style={{ width: '24ch', fontSize: '14pt', marginBottom: '25px' }}
        >
          Enter the email address associated with your IDgo account
        </p>
        {/* the 1st 4 form attributes were added per 2024 WEBSEC pentest recommendations */}
        <form
          spellcheck='false'
          autocomplete='off'
          autocorrect='off'
          autocapitalize='off'
          name='sendEmailForm'
          onSubmit={(e) => onSubmit(e)}
          method='post'
          noValidate
        >
          <div
            id='email-input-group'
            className='form-group form-label-group'
            style={{ marginBottom: '20px' }}
          >
            <input
              id='email-input'
              type='email'
              className='form-control'
              aria-label='New email address'
              name='email'
              value={email}
              onChange={(e) => onChange(e)}
              required
              autoFocus
              autoComplete='off'
              placeholder='Email address'
            />
            <label htmlFor='email-input' className='text-left'>
              Email address
            </label>
            <div className='invalid-feedback'>
              Please enter a valid email address
            </div>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <Link
              to='/'
              className='btn btn-outline-primary btn-sm shadow-none float-left'
            >
              Back
            </Link>
            <button
              type='submit'
              className='btn btn-primary btn-sm shadow-none float-right'
            >
              {' '}
              Continue
            </button>
          </div>
        </form>
      </div>
    </>
  );

  const emailSent = (
    <>
      <div className='text-center mx-auto' style={{ marginTop: '25px' }}>
        <div style={{ marginBottom: '20px' }}>
          <h6>IDgo Admin - {relyingParty?.name}</h6>
          <p style={{ fontSize: '16pt' }}>Password assistance</p>
        </div>
        <label
          className='text-center'
          style={{ marginBottom: '25px' }}
          htmlFor='email'
        >
          A reset email has been sent.
          <br /> <strong>Check your email inbox!</strong>
        </label>
        <Link to={'/#'+gSubdomain} className='btn btn-primary btn-lg shadow-none'>
          Back to Login
        </Link>
      </div>
    </>
  );

  return <div className='fogotpassword-container'>{isSent ? emailSent : emailForm}</div>;
};

export default ForgotPassword;